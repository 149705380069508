@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');
body, h1, h2, h3, h4, h5, h6, div, span, p, b, strong{
  font-family: 'Sora', sans-serif!important;
}
.fseheaderBlogs {
    position: relative;
    top: 0;
    z-index: 99;
    width: 100%;
    
    border-bottom: 1px solid rgb(93 39 218 / 11%);
}
.fseheaderBlogs .container {
    padding: 15px 0px;
}
.IntroduceSection {
    background-image: url(../public/blogs_bg_3.jpg);
    height: 400px;
    width: 100%;
    background-size: 100%;
    background-position: center;
    position: relative;
    background-position: center;
    position: relative;
    display: flex;
    vertical-align: middle;
    align-items: center;
}
.IntroduceSection:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    /* background-color: rgb(248 160 28 / 56%); */
    background-color: rgb(255 255 255 / 47%);
    z-index: 0;
}
.IntroduceSection h1, .IntroduceSection h4 {
    z-index: 9;
    position: relative;
    font-family: 'Sora', sans-serif!important;
}
 
 
.IntroduceSection h1 {
    color: #5c26da;
 
    font-size: 70px;
    text-align: left;
    font-weight: bolder;
    margin: 0;
}
.IntroduceSection h4 {
    color: #030d4e;
    text-align: left;
    font-weight: 300;
    font-size: 45px;
    
}
 
.highlightYellow {
    color: #f8a01c;
    font-weight: 600;
    font-family: 'Sora', sans-serif!important;
}
.flyHighlight {
    font-style: italic;
    font-family: 'Sora', sans-serif!important;
}
.WundrHighlight {
    color: #f8a01c;
    font-family: 'Sora', sans-serif!important;
}
.parentDivforBlogging {
    flex-wrap: wrap;
    display: flex;
    margin: 20px 0px;
    justify-content: flex-start;
}
.displaySetting {
    width: 23.4%;
    margin: 10px;
    position: relative;
    border-radius: 30px 30px 20px 20px!important;
}
.css-r40f8v-MuiTypography-root {
    font-family: 'Sora', sans-serif!Important;
    /* height: 60px; */
    overflow: hidden;
}
.css-h93ljk-MuiTypography-root {
    font-family: 'Sora', sans-serif!Important;
    color: #030d4e;
}
.css-h93ljk-MuiTypography-root {
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.334;
    letter-spacing: 0em;
    margin-bottom: 0.35em;
    min-height: 70px;
    overflow: hidden;
}
.MuiCardActions-root.MuiCardActions-spacing.css-1t6e9jv-MuiCardActions-root {
    position: absolute;
    bottom: 0;
}
button.MuiButtonBase-root.MuiCardActionArea-root.css-b6lsxj-MuiButtonBase-root-MuiCardActionArea-root {
    margin-bottom: 50px;
}
.css-h93ljk-MuiTypography-root {
    font-size: larger;
    font-weight: 600;
}
.css-bhp9pd-MuiPaper-root-MuiCard-root {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px!important;
}
.dealsCart img{   
    box-shadow: rgb(0 0 0 / 42%) 0px 10px 15px -3px, rgb(92 38 218 / 22%) 0px 4px 6px -2px;
    border-radius: 30px;
}
.css-1knaqv7-MuiButtonBase-root-MuiButton-root {
    color: #5c26da;
}
.css-5xe99f-MuiLinearProgress-bar1 {
    background: #f8a01c!important
    }
    .BlogLoader  {
        position: fixed;
        top: 0;
        z-index: 9;
        left: 0;
    }
    .publish_Share_section {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 60px;
        vertical-align: revert;
        align-content: center;
        align-self: center;
        position: relative;
        flex-wrap: wrap;
        margin-bottom: 30px;
    }
    .leftSide_publish img {
        width: 50px;
    }
    span.AuthorSection {
        font-size: 18px;
    }
    span.yellowColor {
        color: #f8a01c;
       /* // font-weight: 900; */
    }
    span.purPleColor {
        color: #5c26da;
        /* font-weight: 900; */
        font-style: italic;
    }
    .personIcon {
        font-size: 46px!important;
        background: #c3c3c3;
        border-radius: 50%;
        padding: 5px;
        color: #ffffff;
        margin-right: 15px;
    }

   
    .leftSide_publish {
        display: flex;
    }
     .date_time_blog {
        display: block;
        color: #757575;
        font-size: 14px;
        font-family: 'Poppins', sans-serif!important;
    }
    .share_section {
        vertical-align: middle;
        align-self: center;
    }
    .Author {
        font-size: 16px;
    }
    .share_section a, .share_sectionIn_bottom a {
        color: #a8a8a8;
        padding: 0px 10px;
    }
    .share_section a:hover, .share_sectionIn_bottom a:hover {
        color: #5c26da;
    }
    .post_title {
        font-size: 32px;
        font-weight: 800;
    }
    .share_sectionIn_bottom {
        display: inline-flex;
        margin-left: 25px;
    }
    @media (max-width: 768px) { 
        .IntroduceSection {
            height: auto;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .IntroduceSection h1 {
            font-size: 50px;
        }
        .IntroduceSection h4{
            font-size: 30px;
        }
        .parentDivforBlogging {
            display: block;
        }
        .displaySetting {
            width: 93%;
        }
     }
     .displaySetting img {
        width: 100%;
        object-fit: cover;
        height: 180px;
        box-shadow: rgb(0 0 0 / 42%) 0px 10px 15px -3px, rgb(92 38 218 / 22%) 0px 4px 6px -2px;
        border-radius: 30px;
    }
    .displaySetting p {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        font-weight: 400;
        height: 60px;
        overflow: hidden;
    }
    .h3custom h3 {
        font-weight: 400;
        font-size: 18px!important;
        color: #030d4e;
    }
    .fullWidth img{
        width: 100%;
    }
    .logoIMg {
        width: 300px!IMPORTANT;
        height: 75px;
    }
    @media (max-width: 900px){
    .fseheaderBlogs .container {
         padding: 10px 10px!important;
        }
    }