@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*, .App, button, body, html, label, p, span, i, input, h1, h2, h3, h4, h5, h6, select, strong, .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root, div {
  /* text-align: center; */
  /* font-family: 'Sora', sans-serif; */
  /* font-family: 'Poppins', sans-serif!important; */
  font-family: 'Sora', sans-serif!important;
  /* background-color: #ccc; */
}

[data-testid~=FlightTakeoffIcon] {
  font-size: 2rem;
    vertical-align: sub;
    line-height: 0;
    color: rgb(92, 38, 218);
}
.fseheader .container {
  padding: 15px 0px;
}.fseheader {
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.fselogo {
  font-size: 24px;
    font-weight: 500;
    line-height: 0;
    color: #ffffff;
} 
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.fselogin{
  text-align: right;
}
.fselogin .css-1rwt2y5-MuiButtonBase-root-MuiButton-root,.fselogin .css-1rwt2y5-MuiButtonBase-root-MuiButton-root:hover {
   border: 1px solid rgb(92, 38, 218) ;
  color: rgb(92, 38, 218);
}
.fseSearch{
  background-image: url(../images/parasuit.jpg);
  background-size: cover;
  min-height: 400px;
  height: 550px;
  width: 100%;
  padding-bottom: 35px;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
  z-index: 9;
}
.fseSearch .container {
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root, .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
  border-radius: 0px!important;
}
.RoundCabin {
  margin-bottom: 20px;
}
.fseSearch h1 {
  color: #ffffff;
    text-align: left;
    font-size: 55px;
    font-weight: 500;
    margin-top: 100px;
    margin-bottom: 30px;
    z-index: 1;
}
.fselogin button {
  text-transform: inherit;
}
.searchFlights {
  /* max-width: 1020px; */
  background: #ffffff;
  padding: 50px;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  width: 100%;
  position: relative;
  backdrop-filter: blur(4px);
  background: linear-gradient(90deg,rgb(243 243 243) 0,rgb(255 255 255 / 61%) 100%);
}
.SearchInputs{
  display: flex;
  justify-content: space-around;
}
.searchButton button, .searchButton button:hover, .searchButton1 button, .searchButton1 button:hover {
  background-color: rgb(92, 38, 218);
  /* background-color: rgb(248 160 28); */
  /* background-color: rgb(3 13 78); */
  text-transform: capitalize;
}
.noborder fieldset{
border: 0px solid #fff;
}
.searchIcon{
  margin-right: 10px;
}
.SearchInputs div{
flex: 1;
}
.searchButton {
  width: 110px;
  position: absolute;
  bottom: -18px;
  right: 0;
  left: 0;
  margin: 0 auto;
}
.searchButton1 {
  width: 100%;
  position: relative;  
  margin: 30px auto;
}
.searchButton1 button {
  width: -webkit-fill-available;
  margin: 20px 0px 0px 0px;
}
.fseSearch:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 15px;
  bottom: 0px;
   background-image: url(../images/border.png);
  z-index: 1;
  /* border-bottom: 10px dotted #d0deed; */
}
 
span#traveller {
  color: #030d4e;
  /* color: #f8a01c; */
  font-weight: 700;
  font-size: 12px;
  margin-top: -8.9px;
}
.SearchInputs label {
  color: #030d4e;
  /* color: #f8a01c; */
  font-weight: 700;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
 
  font-weight: 600;
}
.css-1qqsdnr-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  
  font-weight: 600;
}
.boldCode {
  font-weight: 600;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input, .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  
  font-weight: 600;
}
.RoundCabin div {
  margin: 0px 10px 0px 0px;
}
.fs-8 {
  font-size: 14px;
}

.infoIcon{
  color: rgb(92, 38, 218)!important;
 
}
.bg-fb{
   background-color: #f6f7fb;
}
.infoIcon:hover{
  color: rgb(92, 38, 218);
  background: rgb(92, 38, 218);
}

 
.avatarName {
  font-size: 18px;
}
.fselogo a {
  color: unset;
  text-decoration: none;
}
a{
text-decoration: none!important;
}
.visibility-hidden {
  visibility: hidden;
}

.dividerhash {
  font-size: 24px;
  font-weight: 300;
  vertical-align: middle;
  margin: 0px 15px;
}
.resultsearchbg{
    backdrop-filter: blur(4px);
    background: linear-gradient(90deg,rgb(92 38 218) 0,rgb(153 110 255) 100%);
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    padding: 20px;
    color: #ffffff;
    margin: 0px 2px!Important;
    position: relative;
}
 
.getPriceAlert .css-1rwt2y5-MuiButtonBase-root-MuiButton-root, .getPriceAlert .css-1rwt2y5-MuiButtonBase-root-MuiButton-root:hover {
  border: 1px solid rgb(92, 38, 218) ;
 color: rgb(92, 38, 218);
 text-transform: capitalize;
 font-size: 14px;
}

.css-1w78ofh-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 14px;
}
.fromtodates {
  font-weight: 600;
  font-size: 16px;
}
.fromtoAirports {
  font-weight: 600;
  
}
.visitorQuantity {
  font-size: 14px;
  font-weight: 300;
}
.searchsharp {
  padding: 10px;
  background: #480ecf;
  border-radius: 50%;
  font-size: 40px!important;
  margin-right: 10px;
}
.dividerhash {
  font-size: 20px;
  font-weight: 100;
  vertical-align: middle;
  margin: 0px 5px;
  line-height: 0;
}
.verySmallFontSize{
  font-size: 12px;
}
.flightsInfo {
  width: 100%;
  height: 200px;
}
.bgcolor{
  background: #480ecf;
 
  color: #ffffff;
  padding: 10px 20px;
}
.bgcolor1{
  background-color: #fbfbfb;
  padding: 10px 20px;
  border-right: 1px solid #ccc;
}
.bgcolor2{
  background-color: #fbfbfb;
  padding: 10px 20px;
}
.bgcolor small, .bgcolor1 small, .bgcolor2 small{
  font-size: 12px;
}
.mtl10{
  margin-top: -13px!important;
  margin-left: 30px!important;
  font-size: 10px!important;
}
.size12{
  font-size: 14px;
  color: #030d4e;
}
.size12 .MuiFormControlLabel-label {
  font-size: 12px;
  color: #030d4e;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  /* color: #5d27db!important; */
  color: rgb(91, 38, 216)!important;
}
.css-eg0mwd-MuiSlider-thumb {
  color: rgb(91, 38, 216);
}
.fselogin button div {
  display: none;
  padding: 0px!important;
  box-shadow: none;
}
.fselogin button {
  box-shadow: none!Important;
  border: 0px;
  margin: 0px!Important;
  display: initial!Important;
  /* padding: 0px!Important; */
}
.fselogin button span{
  padding: 0!important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loginData img {
  border-radius: 50%;
  width: 40px;
  margin-right: 10px;
}
.picADjust {
  display: block!important;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
 
  padding-top: 0px!Important;
  padding-bottom: 0px!Important;
  outline: 0;
}
.picBG {
  background: #5c26da;
  height: 50px;
  text-align: center;
}
.picBG img {
  border-radius: 50%;
  width: 70px;
  margin-top: 10px;
}
.loginDataName{
    margin-top: 40px!important;
    text-align: center!important;
    justify-content: center!important;
    font-size: 16px;
    font-weight: 400;
}
.css-t59v3j-MuiButtonBase-root-MuiMenuItem-root{
  font-family: 'Sora', sans-serif!important;
}
.primary-color{
  color: rgb(92, 38, 218);
}
.RowResultEach {
  /* box-shadow: rgb(50 50 93 / 25%) 0px 0px 100px -20px, rgb(91 38 216) 1px 1px 0px 0px, rgb(91 38 214) 0px -2px 0px 0px inset;
  padding: 10px 5px;
  position: relative; */
  padding: 10px 5px;
  position: relative;
  box-shadow: 1px 1px 15px 0px #5f42a357;
  box-shadow: 1px 1px 15px 0px rgb(95 66 163 / 34%);
  border-radius: 10px;
}
.tableResult td:nth-child(1) {
  width: 20%;
  text-align: center;
}

.tableposi {
  color: rgba(72,14,207,.55);
  font-size: 9px;
  position: relative;
  text-align: center;
  width: 30%;
}
.tableResult td:nth-child(2), .tableResult td:nth-child(4) {
  width: 15%;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
.tableResult td:nth-child(5) {
  width: 20%;
  text-align: center;
}
.deals {
  display: block;
  font-size: 12px;
  color: #8f90a0;
}
.totalCost {
  display: block;
  font-size: 12px;
}
.total {
 
  font-size: 12px;
}
.perHead {
  font-size: 20px;
  font-weight: 600;
 display: block
}
.cityFrom, .cityTo {
  font-size: 12px;
  line-height: 1;
  color: #898989;
}
.hourblock, .stops {
  font-size: 10px;
  left: 0;
  position: absolute;
  right: 0;
}
.hourblock {
  color: #000;
  top: 8px;
}
.stops {
   bottom: 8px;
   color: #198754;
  /* color: #f8a01c; */
}
.flightIcon {
  position: absolute;
  transform: rotate(90deg);
  margin-left: -2px;
}
.returnFlight {
  position: absolute;
  transform: rotate(-90deg);
  margin-left: 0px;
}

.RowResultEach:before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
   background: #ffffff;
  top: 0;
  bottom: 0;
  left: 0px;
  margin: auto 0px;
  border-radius: 0px 50% 50% 0px;
  border: 1px solid #adadad;
  border-left: 0px;
}
.RowResultEach:after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  background: #ffffff;
  top: 0;
  bottom: 0;
  right: 0px;
  margin: auto 0px;
  border-radius: 50% 0% 0% 50%;
  border: 1px solid #adadad;
  border-right: 0px;
}
 
button.clickeffect, button.clickeffect:hover{
  background-color: rgb(92, 38, 218);
  /* background-color: rgb(248 160 28); */
    text-transform: capitalize;
}


 
.emission {
    position: absolute;
    right: 0px;
    left: 0;
    margin: auto;
    font-size: 10px;
    top: -8px;
    border-radius: 10px;
    padding: 2px 10px;
    width: -moz-fit-content!important;
    width: fit-content!important;
    background-color: #e6f4ea;
    color: #0d652d;
}
.backToResult {
 
  line-height: 1;
  font-weight: 300;
}
.verti {
  vertical-align: middle;
}
.dividerdot {
  font-size: 16px;
  margin: 0px 10px;
}
.visitorQuantityselected {
  line-height: 1;
  font-weight: 400;
  font-size: 12px;
}

.Compareresult {
  font-size: 32px;
  line-height: 1;
  font-weight: 300;
}
.compareIcon{
  color: #c7b9e9;
}
.bookingScreen .css-o4b71y-MuiAccordionSummary-content {
  display: block;
}
.bookingScreen .tableResult {
  width: 65%;
}
.bookingScreen .tableResult td:nth-child(1){

  text-align: left;
  
}
.bookingScreen .stops {
  position: absolute;
  bottom: 0px;
  font-size: 10px;
  left: 0;
  right: 0;
  color: #198754;
  /* color: #f8a01c; */
}

.bookingScreen .hourblock {
  position: absolute;
  top: 0px;
  font-size: 10px;
  left: 0;
  right: 0;
  

}
.otDate {
  font-size: 20px;
  color: #898989;
  font-weight: 400;
}
.detailedInfo {
  position: relative;
  margin-left: 20px;
}
.time-cont {
  font-size: 14px;
  font-weight: 500;
}
.travelTime {
  font-size: 12px;
  color: #70757a;
  margin: 20px 0px;
}

li.nodot {
  list-style: none;
  padding: 0;
  margin: 15px 0px;
  line-height: 1;
  font-size: 12px;
}
.detailedInfo li.dot {
  list-style: circle;
  font-size: 25px;
  color: #838383;
}
.detailedInfo:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 50%;
  border-left: 4px dotted #e3e3e3;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: 11px;
}
.time-cont {
  font-weight: 500;
  font-size: 20px;
  color: #000;
}
.f20{
font-size: 20px;
}
.footer{
  background: #5c26da08;
}
.bookYourTicket {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.posiAbs {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #ffffff;
}
.backArrow {
  padding: 10px;
 
  border-radius: 50%;
  color: #fff!Important;
  margin-right: 10px;
}
.flex-cs {
  display: flex;
}

.boxRow{
justify-content: space-between;
display: flex;
/* flex-direction: row-reverse; */
width: 100%;
padding: 5px 10px;
cursor: pointer;
font-weight: 500;
}
.boxRow:hover{
  background-color: #f6f7fb;
  color: #5c26da;
}

/* #loaderStyle {
  display: none;
} */
.optionname {
  display: block;
  font-size: 12px;
}
/* .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root, .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color: #5c26da!important;
  font-weight: 700!important;
} */
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
     border-color: rgba(0, 0, 0, 0.23)!important;
     border-radius: 0px !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  font-weight: 600;
}
.errorMsg {
  background: #b71c1c;
  color: #ffffff;
  padding: 10px;
  font-size: 20px;
  font-weight: 400;
}
.loader {
  background-image: url(../images/Flight_GIF_PP.gif);
  height: 120px;
    background-size: 101%;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    overflow: hidden;

}
.loaderParent {
  background: #fff;
  width: 67%;
  justify-content: center;
  margin: auto;
  /* height: 100px; */
  height: 120px;
  overflow: hidden;
}
.colinfo {
  border-right: 0px;
    justify-content: center;
    text-align: left;
    color: #030d4e;
    padding: 10px;
    align-items: center;
    align-content: center;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
}
.rowCs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* background: #030d4e; */
  margin: 10px 0px;
  box-shadow: 1px 1px 15px 0px rgb(95 66 163 / 34%);
  border-radius: 10px;
}
:focus-visible {
  outline: -webkit-focus-ring-color auto 0px!important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: rgb(3 13 78 / 21%)!important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #030d4e!important;
}
.SwapHorizIcon {
  position: absolute;
  right: -30px;
  top: 0;
  border-radius: 50%;
  background: white;
  padding: 4px;
  bottom: 0;
  display: inline-table;
  margin: auto;
}
.searchButtn svg {
  color: #ffffff;
}
span.searchButtn {
  background: rgb(92, 38, 218);
  padding: 10px;
  width: 45px;
  height: 45px;
  display: block;
  border-radius: 50%;
  margin-top: 5px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}
.colinfo.positionRel {
  position: relative;
    margin-right: 30px;
     
}
 
.SwapHorizIcon svg{
  color: rgb(92, 38, 218);
  }
.colinfo div {
  color: #030d4e;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 11px;
}
@media (max-width: 900px) {
  .mobileFilter {
    display: inline-block;
    position: absolute;
    padding: 10px;
    left: 0;
    z-index: 9;
}
#dropdownMenuButton1 {
 
  padding: 6px 0px!important;
 
}
.hiddenInDeskTop {
  background: #ffffff;
  color: #030d4e;
  border: 0px solid #030d4e;
  border-radius: 0px;
  padding: 6px 10px;
  font-size: 16px;
  width: 100%;
  text-align: right;
}
  .hiddenInMobile {
    display: none!important;
  }
  .searchFlights {
    padding: 20px;
}
.SearchInputs {
  display: block;
}
.SearchInputs div {
   margin: 3px 0px;
}
.fseSearch h1{
  font-size: 42px;
}
.flex-cs {
  display: inline-block;
  text-align: center;
}
.flex-cs div{
  display: inline-block;
}
.container, .container-fluid{
  margin-right: 0px;
    margin-left: 0px;
}
body, .App {
  overflow-x: hidden;
}
.d-flex {
  display: flex!important;
}
.fseheader .d-flex {
  display: flex!important;
  justify-content: space-between;
  padding: 0px 10px;
}
.hourblock {
    
  top: 0px;
  
  left: 0;
  right: 0;

}
.stops {
  bottom: 0;
}
.tableResult td {
  display: block;
  width: 100%!Important;
  padding: 10px;
}
 
.flightIcon {
  position: absolute;
  transform: rotate(90deg);
  margin-left: -2px;
}
.bgcolor {
text-align: center;
}
.bgcolor1, .bgcolor2 {
text-align: center;
}
.bookingScreen .tableResult {
  width: 100%;
}
.fromtoAirports {
  font-weight: 600;
  font-size: 13px;
}
.bookingScreen .tableResult td:nth-child(1) {
  text-align: center;
}
.detailedInfo li.dot {
  list-style: circle;
  font-size: 25px;
  color: #838383;
  line-height: 1;
}
.time-cont {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}
.detailedInfo:before {
  height: 56%;
  border-left: 2px dashed #e3e3e3;
  top: 0px;
  bottom: 0;
  margin: 24px 0 0 0;
  left: 11px;
}
.listBlock {
  display: block!Important;
  width: 100%;
}
.avatarPic div {
  text-align: center;
  margin: auto;
}
.trackCode {
  text-align: center;
}
.selectPrice p {
  text-align: center;
  margin: auto;
  align-items: center;
  align-content: center;
  width: 100%;
}
.selectBtn {
  text-align: center!Important;
}

.css-uls1yk-MuiDivider-root {
margin-left: 0px!important;
}
.resulthead .row{
margin-left: 0px;
margin-right: 0px;
}
}
.tinyText {
  font-size: '12px';
  letter-spacing: 0.2
}
.flightDetailspopup {
  margin: 0px 15px;
  padding: 10px 20px!important;
  box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 18%);
  border-radius: 5px;
}
.departHeading {
  font-size: 14px;
  font-weight: bold;
  color: #030d4e;
}
.faresDetailsCard {    
  padding: 10px 10px;
  box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 18%);
  border-radius: 5px;
  width: 100%!important;
  margin: 4px 0px;
  position: relative;    
}
.totalAmount {
  font-size: 21px;
  font-weight: 500;
  vertical-align: middle;
  color: #000000;
}
.currencyCode {
  font-weight: 500;
    vertical-align: middle;
    color: #000000;
    font-size: 14px;
    margin-right: 5px;
}
.smallCurrencyCode {
 
  vertical-align: middle;
 
  color: #4a4a4a;
}
.airlineLogo {
  height: 35px;
  object-fit: contain;
}
.flightDetailspopupRoundTrip {
  margin: 0px 0px;
  padding: 10px 20px!important;
 
 
}
.modal-content {
  padding-bottom: 20px;
  border-radius: 10px;
}

.noDaTa1{
  width: 100%;
  height: auto;
  position: relative;
  
  
  width: 100%;
  /* justify-content: center; */
  text-align: center;
  font-size: 32px;
  font-weight: 300;
  color: #9b80d7;
  margin: auto;
 
}
.deal {
    font-size: 14px;
    font-weight: 400;
    color: #f8a01c;
}
.paddAdjustment {
  padding: 2rem!important;
  margin: 0px;
}
.hiddenInDesktop {
  display: none;
}
.hiddenInMobile {
  display: revert;
}
.dateTimeDepart  {
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
  color: rgb(91, 38, 216);
}
.BigDashs {
  width: 100%;
  height: 2px;
  background: #ffffff;
  display: inline-block;
  border: 1px dashed rgb(3 13 78 / 35%);
}
@media (min-width: 900px){
  .hiddenInDeskTop {
    display: none;
    border: 0px solid #ffffff;
    color: #fff;
  }  

}
@media (max-width: 900px) {
  
 
  #hideINTabMob {
    display: none;
  }
  .paddAdjustment {
    padding: 10px 25px!Important;
    margin: 0px;
}
  .fseSearch {
   
  
    min-height: 400px;
    height: auto;  
    
}
 
.SearchInputs div {
    margin: 10px 0px;
    display: block;
}
.css-1qqsdnr-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    width: 100%!important;
    min-width: 100%;
}
.rowCs {
    width: 100%;
    display: flex;
    justify-content: center;
    display: none;
}

.onlyViewInDesktop {
  display: none!important;
}
.onlyMobileView {
  display: block!important;
}
.fseheader .container {
  padding: 10px 10px;
}
.mobCurr {
  visibility: visible!important;
  position: relative;
}
.DesktopCurr {
  display: none;
}
/* .menuCloseList {
  display: none!important;
} */
.fselogo {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.mobCurr svg {
  margin: 0 0px 0px 10px;
}
.MenuMobileList {
  position: absolute;
  z-index: 99;
  line-height: 1;
  right: 0;
  width: 130px;
  padding: 10px 10px;
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
  border-radius: 5px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background: linear-gradient(90deg,rgb(243 243 243) 0,rgb(255 255 255 / 61%) 100%);
}

.MenuMobileList a {
    display: block;
    font-weight: 500;
    color: #030d4e;
    padding: 10px 0px;
    text-align: center;
}
.mobileRowIrrtn {
  margin-right: 0px!Important;
}
#searchYahoo{
  margin: 5px 5px!Important;
  width: 100%!Important;
}
.TitleHeader {
  font-size: 23px!important;
  font-weight: 700!important;
  text-shadow: none!important;
}
 
}
.mobCurr {
  visibility: hidden;
}
.onlyMobileView {
  display: none;
}
.onlyViewInDesktop {
  display: block;
}
.flightDetailspopupTwowayTrip {
  margin: 0px 0px;
  padding: 0px 0px!important;
}
.bxShad {
  box-shadow: 1px 1px 15px 0px rgb(3 13 78 / 22%);
  border-radius: 10px;
  margin-top: 10px!Important;
}
.bottomLine {
  border-bottom: 1px solid #dee2e6;
  font-size: 18px;
  padding: 12px;
  text-transform: capitalize;
  color: rgb(91, 38, 216)!important;
  font-weight: 400;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  color: #030d4e;
  font-weight: bolder;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0px 1.5rem!important;
}
.modal-header {
  border: 0px solid #ffffff!important;
}
.flightDetailspopupTwowayTrip:nth-child(1) {
  border-right: 1px solid #dee2e6;
}
.sortBySection {
  display: flex;
  text-align: end;
  justify-content: flex-end;
  align-items: center;
}

.sortBySection label {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  width: 50px;
}
.sortBySection select {
  width: 100px;
  margin-left: 10px;
  font-size: 14px;
}
.flightDetailspopupTwowayTrip .col-md-12 {
  padding: 15px;
}
.logoMain {
  width: 150px;
}
.sortbyDropDown  {
  text-align: right;
}
#dropdownMenuButton1 {
  background: #ffffff;
  color: #030d4e;
  border: 0px solid #030d4e;
  border-radius: 0px;
  padding: 6px 30px;
  font-size: 16px;
  white-space: pre-wrap;
}
.dropdown-item {  
  color: #030d4e;
  cursor: pointer;
}
.RoundCabin label {
  color: #030d4e;
  /* color: #f8a01c; */
  font-weight: 700;
}
.css-ahj2mt-MuiTypography-root {
  font-size: 14px!Important;
}.flex-cs a {
  /* font-size: 0.9vw; */
  font-size: 16px;
}
article {
  font-size: 16px;
}
.footer small {
  font-size: 14px;
}
.banner {
  height: 60vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
}
.banner:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgb(0 0 0 / 54%);
}
.banner h1 {
    /* color: rgb(92, 72, 41); */
    height: 60vh;
    margin: auto;
    font-size: 4.5rem;
    color: rgb(255 255 255);
    display: flex;
    vertical-align: middle;
    align-items: center;
    width: 100%;
    font-weight: 200;
}
.positionRel {
  position: relative;
}
.leftsideCol {
  width: 31%;
  flex-basis: 31%!important;
}
.rightsideCol {
  flex-basis: 65%;
  width: 65%;
}
.wp-container-3 {
  padding: 10vh 0vw;
}
.rightsideCol p {
  color: #292929;
  font-size: 1.3rem;
  font-weight: 400;
}
.leftsideCol h2 {
  font-size: 4rem!important;
  
}
img.wp-block-cover__image-background.wp-image-58 {
  width: 100%;
  object-fit: cover;
}

.wp-block-cover__inner-container {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: fit-content;
  text-align: center;
  padding: 0px 5vw;
}

.wp-block-cover:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgb(0 0 0 / 54%);
}
.wp-block-cover {
  position: relative;
  height: 70vh;
  overflow-y: hidden;
}
.wp-block-columns.is-layout-flex.wp-container-8 {
  padding: 10vh 0vw;
}
.wp-block-cover__inner-container p {
  font-size: 2.5rem!important;
  font-weight: 200;
  line-height: 1.3;
}
.dealsCartBody{
  border-radius: 30px 30px 10px 10px!important;
}
input#from, input#to {
  width: 100%;
}
.headerMenu {
  color: #030d4e!important;
  font-size: 16px;
  padding: 5px 15px;
  font-weight: 600;
}
.headerMenu:hover {
  text-decoration: underline!important;
  color: #030d4e;
}
.cuurSelect {
  color: #030d4e;
  display: flex;
  align-self: inherit;
  line-height: 0;
  font-size: 25px;
  cursor: pointer;
}
.heading h6 {
  color: #030d4e;
  font-size: 25px;
  text-transform: capitalize;
  font-weight: 600;
  margin: 15px 0px;
}
.popupheadercustom {
    position: absolute;
    right: 30px;
    top: 25px;
    font-size: 14px;
    padding: 0px;
    z-index: 9;
}
.CountrySelect, .CurrencySelect {
  width: 100%!Important;
  margin: 30px 0px;
}
/* .fseSearch:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  top: 0;
  z-index: 0;
  background: linear-gradient(0deg, #4e1a5e, transparent);
} */

/* .posirelative {
  position: relative!important;
} */
/* .modal-backdrop.show {
  
  z-index: 9;
} */

.form-label {
  margin-bottom: 0.5rem;
  font-weight: 400;
  font-size: 14px;
}
.typoStyle p {
  flex: 1;
  width: 50%;
  font-size: 0.8rem;
  position: relative;
  top: 0;
}
/* input#searchYahoo {
  margin: 0px 10px;
  border: 3px solid #030d4e;
  border-radius: 20px;
  padding: 2px 10px;
  font-size: 14px;
  width: 250px;
  background: #030d4e7d;
  color: #ffffff;
} */
input#searchYahoo {
  margin: 0px 10px;
  border: 0px solid #ffffff;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 14px;
  width: 250px;
  /* background: #ffffff; */
  color: #030d4e;
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background: linear-gradient(90deg,rgb(243 243 243) 0,rgb(255 255 255 / 61%) 100%);
}

#searchYahoo.BlogheadsearchInput{
  border: 2px solid #595e83;
  background: #ffffff;
  box-shadow: none;
}
#searchYahoo::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  /* color:  rgb(255 255 255 / 47%); */
  color: #030d4e;
  opacity: 1; /* Firefox */
}

#searchYahoo:-ms-input-placeholder { /* Internet Explorer 10-11 */
  /* color:  rgb(255 255 255 / 47%); */
  color: #030d4e;
}

#searchYahoo::-ms-input-placeholder { /* Microsoft Edge */
  /* color:  rgb(255 255 255 / 47%); */
  color: #030d4e;
}
.dropdown-menu.show {
  
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
  border: none;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #030d4e!important;
}
