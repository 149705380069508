.tableResult tbody, .tableResult td, .tableResult tfoot, .tableResult th, .tableResult thead, .tableResult tr {
    border: 0px solid #efedf6!important;
  }
  .RoundTripDiv .tableResult {
    width: 80%;
}
.viewDealSection {
    width: 20%;
    display: flex;
    align-items: center;
}